import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Audiences } from './Audiences';

import { LeadDetails, Leads } from './leads';
import { Actions } from './Actions';
import AppContext from '_context/AppContext';
import { useOrientation } from '@uidotdev/usehooks';

export {
    Dashboard
}

function Dashboard() {
    const { user: authUser } = useSelector(x => x.auth);
    const { selectedLead, setSelectedLead } = useContext(AppContext);
    const [selectedAudience, setSelectedAudience] = useState(null);
    const [showAudiencesSection, setShowAudiencesSection] = useState(true);
    const [showLeadsSection, setShowLeadsSection] = useState(true);
    const orientation = useOrientation();
    
    useEffect(() => {
        if (selectedLead) {
            if (window.screen.width < 850) {
                setShowAudiencesSection(false);
                setShowLeadsSection(false);
            } else {
                setShowAudiencesSection(true);
                setShowLeadsSection(true);
            }
        } else {
            if (selectedAudience) {
                setShowLeadsSection(true);
                if (window.screen.width < 850) {
                    setShowAudiencesSection(false);
                } else {
                    setShowAudiencesSection(true);
                }
            } else {
                setShowAudiencesSection(true);
                if (window.screen.width < 850) {
                    setShowLeadsSection(false);
                } else {
                    setShowLeadsSection(true);
                }
            }
        }
    }, [window.screen.width, orientation.type])

    useEffect(() => {
        if (selectedLead) {
            if (window.screen.width < 850) {
                setShowAudiencesSection(false);
                setShowLeadsSection(false);
            } else {
                setShowAudiencesSection(true);
                setShowLeadsSection(true);
            }
        }
    }, [selectedLead])

    useEffect(() => {
        return function() {
            setSelectedLead(null)
        }
      }, []);

    const handleAudienceClicked = (audience) => {
        setSelectedAudience(audience);
        setSelectedLead(null)
        if (window.screen.width < 850) {
            setShowAudiencesSection(false);
            setShowLeadsSection(true);
        }
    }

    const handleLeadsBackClicked = () => {
        if (window.screen.width < 850) {
            setShowAudiencesSection(true);
            setShowLeadsSection(false);
        } else {
            setShowLeadsSection(true);
        }
    }

    
    const handleLeadDetailsBackClicked = () => {
        setSelectedLead(null);
        if (window.screen.width < 850) {
            if (selectedAudience) {
                setShowLeadsSection(true);
            } else {
                setShowAudiencesSection(true);
                setShowLeadsSection(false);
            }
        }
    }

    return (
        <>
            <Actions/>
            <div className="row mt-3">
                {showAudiencesSection === true ?
                    <>
                        <div className='col-lg-4-30 shadow-right' >
                            <Audiences handleAudienceClicked={handleAudienceClicked} />
                        </div>
                        <div className='dashboard-column-divider' ></div>
                    </>
                : null}
                {showLeadsSection === true ?
                    <>
                        <div className='col-lg-4-30 shadow-right'>
                            <Leads 
                                audience={selectedAudience} 
                                showLeadsSection={showLeadsSection} 
                                handleClicked={setSelectedLead}
                                handleBackClick={handleLeadsBackClicked}
                            />
                        </div>
                        <div className='dashboard-column-divider'></div>
                    </>
                : null}
                {selectedLead &&
                    <div className='col-lg-4-30 shadow-right'>
                        <LeadDetails
                            lead={selectedLead}
                            handleBackClick={handleLeadDetailsBackClicked}
                        />
                    </div>
                }
            </div>
        </>
    )

}