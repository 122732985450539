import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";

import { constants, fetchWrapper } from '_helpers';
import { EventOfferTemplate, RealEstateOfferTemplate } from './templates';
import { Loading } from '_components';

export function Edit() {
    const params = useParams();
    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadOffer();
    }, []);

    const loadOffer = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + params.id).then(response => {
            setOffer(response.data);
            setLoading(false);
        });
    }


    function renderSwitch(param) {
        switch (param) {
            case constants.offerRealEstateTemplateId:
                return <RealEstateOfferTemplate offer={offer} selectedTemplateId={offer.templateId} />;
            case constants.offerEventTemplateId:
                return <EventOfferTemplate offer={offer} selectedTemplateId={offer.templateId} />;
            default:
                return null;
        }
    }

    return (
        <>
            {loading === true ? <Loading /> :
                <>
                    <div className="row justify-content-center">
                        <div className="col">
                            <h3 className="mt-4 text-center">Update Offer</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            {offer !== null ?
                                <>
                                    {renderSwitch(offer.templateId)}
                                </>
                                : null}
                        </div>
                    </div>
                </>
            }
        </>
    )

}