import { Loading, NoResults } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { LeadOfferStateChange } from ".";
import { OfferPreview } from "vendor/offers";
import moment from "moment";

export function Offers({ leadId }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [statusDialog, setStatusDialog] = useState({
        show: false,
        id: null
    })
    const [previewDialog, setPreviewDialog] = useState({
        show: false,
        code: null
    })

    useEffect(() => {
        loadOffers();
    }, [leadId])

    const loadOffers = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/offers").then(response => {
            setOffers(response.data);
            setTotalElements(response.data.length)
            setLoading(false)
        });
    }

    const handleStateChange = () => {
        setStatusDialog({ show: false });
        loadOffers();
    }

    function previewOffer(id) {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + id + "/preview").then(response => {
            if (response.status === 200) {
                setPreviewDialog({ show: true, code: response.data.value })
            }
        });
    }

    return (
        <>
            <div className="row mt-2" style={{ backgroundColor: "#e2e2e2" }}>
                <div className="col">
                    <h6 className="mt-1 mb-1"><strong>Offers{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>
                </div>
            </div>
            {loading === true ? <Loading /> :
                offers.length === 0 ?
                    <div className="row justify-content-center mt-2">
                        <div className="col">
                            <NoResults />
                        </div>
                    </div>
                    :
                    <table className="offer-table">
                        <tbody>
                            {offers.map((item) =>
                                <tr key={item.offerId}>
                                    <td>
                                        <p className="mb-1 mt-1 ms-2"><a className="cursor" onClick={() => previewOffer(item.offerId)} >{item.offerTitle}</a></p>
                                        <p className="mb-0 ms-2 fs-6">From {moment(item.offerCreationDate).format('MMM Do YYYY')}</p>
                                    </td>
                                    <td className="w-10 align-middle text-center">
                                        <a className="cursor" onClick={() => setStatusDialog({ show: true, id: item.offerId, state: item.state })} >
                                            {item.state === null ?
                                                "Sent" :
                                                <strong style={{ textTransform: "uppercase" }}>{t("LEADOFFERSTATE." + item.state)}</strong>
                                            }
                                        </a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
            }
            <Modal show={statusDialog.show} onHide={() => setStatusDialog({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Change State</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeadOfferStateChange
                        leadId={leadId}
                        offerId={statusDialog.id}
                        selectedState={statusDialog.state}
                        callback={handleStateChange}
                    />
                </Modal.Body>
            </Modal>
            <OfferPreview
                show={previewDialog.show}
                code={previewDialog.code}
                cancelCallback={() => setPreviewDialog({ show: false })}
            />
        </>
    )


}