export const constants = {
    phoneRegExp: /^\+[1-9]\d{1,14}$/,
    websiteRegExp: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?;/gm,
    linkedin: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm,
    instagram: /^((http|https):\/\/)?(www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/igm,
    facebook: /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/ig,
    twitter: /^((http|https):\/\/)?(www.)?twitter\.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)/gm,
    industries: ['GENERAL', 'PERSONAL', 'REALTORS', 'DEALERSHIP'],
    currencies: ['CAN', 'USD', 'EUR'],
    noteTypes: ['REMARK', 'MEETING', 'CALL', 'PRESENTATION', 'DEMO', 'WARNING', 'REQUEST'],
    offerRealEstateTemplateId: 1,
    offerEventTemplateId: 2,
    channelName: /^[a-zA-Z0-9]*$/,
    tagsBackgroudColor: ["#EE826C", "#3D5A98", "#BC3081", "#F47133", "#FED576", "#4C63D2", "#198754"]
}