import { useState, useEffect } from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { constants, fetchWrapper } from "_helpers";
import { RealEstateOfferTemplate, ImagePreview, EventOfferTemplate } from "./templates";

export function New() {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.screen.width,
        dynamicHeight: window.screen.height
    });
    const [templatePreviewDialog, setTemplatePreviewDialog] = useState({
        show: false,
        imageSrc: null,
        mobileImageSrc: null
    });

    useEffect(() => {
        loadTemplates();
    }, [0]);

    const loadTemplates = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/offer-templates", { "pageNumber": 0, "pageSize": 30 }).then(response => {
            setTemplates(response.data);
        });
    }

    function renderSwitch(param) {
        switch (param) {
            case constants.offerRealEstateTemplateId:
                return <RealEstateOfferTemplate selectedTemplateId={selectedTemplate.id} />;
            case constants.offerEventTemplateId:
                return <EventOfferTemplate selectedTemplateId={selectedTemplate.id} />;
            default:
                return null;
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h3 className="mt-4 text-center">Create Offer</h3>
                </div>
            </div>
            <div className="row mb-2 " >
                <CarouselProvider
                    naturalSlideWidth={25}
                    naturalSlideHeight={24}
                    visibleSlides={screenSize.dynamicWidth < 850 ? 1 : 4}
                    totalSlides={templates.length}
                >
                    <Slider style={{ height: "290px", backgroundColor: "#c3c3c3" }} >
                        {templates.map((template, index) =>
                            <Slide index={index} >
                                <div key={template.id} >
                                    <h5 className="mt-2 mb-2 text-center">{template.title}</h5>
                                    <div className="card" style={{ backgroundColor: "#ffffff" }} >
                                        <img src={"/uploads/offer/" + template.image} className="card-img-top" alt={template.title} style={{ maxHeight: "200px" }} />
                                    </div>
                                    <div className="mt-2 mb-2 text-center">
                                        <button onClick={() => setTemplatePreviewDialog({ show: true, imageSrc: template.image, mobileImageSrc: template.mobilePreviewImage })} className="btn btn-link btn-sm rounded-pill ms-1 me-1">Prev</button>
                                        <button onClick={() => setSelectedTemplate(template)} className="btn btn-link btn-sm rounded-pill">Use</button>
                                    </div>
                                </div>
                            </Slide>
                        )}
                    </Slider>
                    {(templates.length > 4 || screenSize.dynamicWidth < 850) &&
                        <>
                            <ButtonBack className="btn btn-link float-start mt-2">Previous</ButtonBack>
                            <ButtonNext className="btn btn-link float-end mt-2">Next</ButtonNext>
                        </>
                    }
                </CarouselProvider>
            </div>

            {selectedTemplate !== null ?
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        {renderSwitch(selectedTemplate.id)}
                    </div>
                </div>
                : null}
            <ImagePreview
                show={templatePreviewDialog.show}
                imageSrc={templatePreviewDialog.imageSrc}
                mobileImageSrc={templatePreviewDialog.mobileImageSrc}
                handleCancel={() => setTemplatePreviewDialog({ show: false })}
            />
        </>
    )

}