import { useState, useEffect, forwardRef } from "react";

import { constants, fetchWrapper } from "_helpers";
import { Loading } from "_components";
import { EventOfferLandingPage, RealEstateOfferLandingPage } from "./offer/templates";
import { Subscribe } from "lead";

const Offer = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState(null);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/offer/" + props.channelName + "/" + props.invitationCode).then(response => {
            if (response.status === 200) {
                setOffer(response.data);
                setLoading(false);
            }
        });
    }, [])

    function renderSwitch(param) {
        switch (param) {
            case constants.offerRealEstateTemplateId:
                return <RealEstateOfferLandingPage
                    offer={offer}
                    channelName={props.channelName}
                    invitationCode={props.invitationCode}
                    preview={props.preview}
                >
                    <Subscribe
                        label="Connect with us"
                        channelName={props.channelName}
                        invitationCode={props.invitationCode}
                        callToAction={offer.callToSubscribe}
                    />
                </RealEstateOfferLandingPage>;
            case constants.offerEventTemplateId:
                return <EventOfferLandingPage
                    offer={offer}
                    channelName={props.channelName}
                    invitationCode={props.invitationCode}
                    preview={props.preview}
                ><Subscribe
                        label="Connect with us"
                        channelName={props.channelName}
                        invitationCode={props.invitationCode}
                        callToAction={offer.callToSubscribe}
                    />
                </EventOfferLandingPage>;
            default:
                return null;
        }
    }

    return (
        <div ref={ref}>
            {loading === false ?
                renderSwitch(offer.templateId)
                : <Loading />}
        </div>
    )

});

export default Offer;