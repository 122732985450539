export {
    TextInputField
}

function TextInputField({register, error, label, name, maxLength, textarea, type}) {

    return (
        <div className="form-group mb-2">
            <label className="mb-0">{label}</label>
            {textarea === false || textarea === undefined ?
                <input type={type !== undefined ? type : 'text'} {...register(name)} maxLength={maxLength} className={`form-control ${error ? 'is-invalid' : ''}`}/>
            :
                <textarea type="text" {...register(name)} className={`form-control ${error ? 'is-invalid' : ''}`} rows="3" ></textarea>
            }
            <div className="invalid-feedback">{error?.message}</div>
        </div>
    )

}
